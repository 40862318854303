import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreatorProfileService {

  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }

  getall(params = {}) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/artist`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getCountry() {
    return this.http.get(`${this.apiUrl}/creator/country`);
  }

  getallartist() {

    return this.http.post(`${this.apiUrl}/artist/dropdown`, { search: "" });
  }


  changestatus(id, status) {
    return this.http.put(`${this.apiUrl}/artist/changes-status/${id}`, { status });
  }

  updateGeneral(admin) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/creator/update/profile`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  updateinfo(admin) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/creator/update/info`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  updatesocialLink(admin) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/creator/update/social/info`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }


  updatePassword(pw) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/creator/change/password`, pw).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }


  getById(id) {
    return this.http.patch(`${this.apiUrl}/artist/${id}`, {});
  }

  getDetail() {
    return this.http.patch(`${this.apiUrl}/creator/detail`, {});
  }

  create(params = {}) {
    this._isLoading$.next(true);

    return this.http.post(`${this.apiUrl}/artist/add`, params).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  update(admin: any, id: any) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/artist/${id}`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  delete(id) {
    return this.http.delete(`${this.apiUrl}/artist/${id}`);
  }
}
