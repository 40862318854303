import { Routes, RouterModule } from "@angular/router";
import { PermissionGuard } from "app/core/service/guard/permission.guard";
import { UnderPreviewGuard } from "app/core/service/guard/under-preview.guard";
import { Roles } from "app/roles";
const {
  ADMIN,
  MANAGER,
  CREATOR,
  RIGHT_HOLDER,
  MANAGER_LIMITED,
  VIEWER,
  VIEWER_LIMITED,
} = Roles;

//Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },

    loadChildren: () =>
      import("app/modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: "creator-analytics",
    data: { permissions: [CREATOR] },

    loadChildren: () =>
      import("app/modules/creator-dashboard/creator-dashboard.module").then(
        (m) => m.CreatorDashboardModule
      ),
  },
  {
    path: "profile",
    data: { permissions: "ALLOW_ALL" },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/creator-profile/creator-profile.module").then(
        (m) => m.CreatorProfileModule
      ),
  },

  {
    path: "admins",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "community",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/community/community.module").then((m) => m.CommunityModule),
  },
  {
    path: "playlist",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/playlist/playlist.module").then((m) => m.PlaylistModule),
  },
  {
    path: "pop-up",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/pop-up/pop-up.module").then((m) => m.PopUpModule),
  },
  {
    path: "breakup-row",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/breakup/breakup.module").then((m) => m.BreakupModule),
  },
  {
    path: "suggested",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/suggested/suggested.module").then((m) => m.SuggestedModule),
  },
  {
    path: "search-settings",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/search-setting/search-setting.module").then((m) => m.SearchSettingModule),
  },
  {
    path: "onboarding-bucket",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/onbording-bucket/onbording-bucket.module").then((m) => m.OnbordingBucketModule),
  },
  {
    path: "settings",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/settings/settings.module").then((m) => m.SettingsModule),
  },

  {
    path: "audios",
    data: { permissions: [ADMIN, CREATOR, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard, UnderPreviewGuard],
    loadChildren: () =>
      import("app/modules/audio/audio.module").then((m) => m.AudioModule),
  },
  {
    path: "videos",
    data: { permissions: [ADMIN, CREATOR, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard, UnderPreviewGuard],
    loadChildren: () =>
      import("app/modules/video/video.module").then((m) => m.VideoModule),
  },
  {
    path: "alram-audio",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/alarm-audio/alarm-audio.module").then((m) => m.AlarmAudioModule),
  },

  {
    path: "mindset-scheduler",
    data: { permissions: [ADMIN, CREATOR, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard, UnderPreviewGuard],
    loadChildren: () =>
      import("app/modules/schduler/mindset-scheduler/mindset-scheduler.module").then((m) => m.MindsetSchedulerModule),
  },
  {
    path: "users",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "creator",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/creator/creator.module").then((m) => m.CreatorModule),
  },

  {
    path: "communication",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        CREATOR,
      ],
    },
    loadChildren: () =>
      import("app/modules/communication/communication.module").then(
        (m) => m.CommunicationModule
      ),
  },
  {
    path: "dashboard-graphs",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard-graphs/dashboard-graphs.module").then(
        (m) => m.DashboardGraphsModule
      ),
  },

  {
    path: "creators",
    loadChildren: () =>
      import("app/modules/creator/creator.module").then((m) => m.CreatorModule),
  },
  {
    path: "contracts",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/contracts/contracts.module").then(
        (m) => m.ContractsModule
      ),
  },
  {
    path: "right-holders",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/right-holders/right-holders.module").then(
        (m) => m.RightHoldersModule
      ),
  },
  {
    path: "channels",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/channels/channels.module").then(
        (m) => m.ChannelsModule
      ),
  },
  {
    path: "drm-youtube-videos",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/drm-youtube-videos/drm-youtube-videos.module").then(
        (m) => m.DrmYoutubeVideosModule
      ),
  },
  {
    path: "auto-synce-channels",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/monitoring-channels/monitoring-channels.module").then(
        (m) => m.MonitoringChannelsModule
      ),
  },
  {
    path: "downloaded-audios",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import(
        "app/modules/monitoring-youtube-vedios/monitoring-youtube-vedios.module"
      ).then((m) => m.MonitoringYoutubeVediosModule),
  },
  {
    path: "categories",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/category/category.module").then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: "energy-level",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/energy-level/energy-level.module").then(
        (m) => m.EnergyLevelModule
      ),
  },
  {
    path: "artists",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/artist/artist.module").then((m) => m.ArtistModule),
  },
  {
    path: "hashtags",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/hashtags/hashtags.module").then(
        (m) => m.HashtagsModule
      ),
  },
  {
    path: "ads-management",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/ads-management/ads-management.module").then(
        (m) => m.AdsManagementModule
      ),
  },
  {
    path: "content-library",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/content-library/content-library.module").then(
        (m) => m.ContentLibraryModule
      ),
  },
  {
    path: "update-profile",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "royalties-earnings",
    data: { permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, VIEWER] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },
  {
    path: "royalties",
    canActivate: [PermissionGuard],
    data: { permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, VIEWER] },
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },
  {
    path: "payment-history",
    loadChildren: () =>
      import("app/modules/payment-history/payment-history.module").then(
        (m) => m.PaymentHistoryModule
      ),
  },
  {
    path: "payment-setting",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/payment-setting/payment-setting.module").then(
        (m) => m.PaymentSettingModule
      ),
  },
  // {
  //   path: "pages",
  //   loadChildren: () =>
  //     import("app/pages/full-pages/full-pages.module").then(
  //       (m) => m.FullPagesModule
  //     ),
  // },

  {
    path: "inquiry-list",
    canActivate: [PermissionGuard],
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    loadChildren: () =>
      import("app/modules/inquiry-list/inquiry.module").then(
        (m) => m.InquiryModule
      ),
  },

  {
    path: "dashboard-channel",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-channel/dashboard-channel.module").then(
        (m) => m.DashboardChannelModule
      ),
  },
  {
    path: "dashboard-podcast",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-podcast/dashboard-podcast.module").then(
        (m) => m.DashboardPodcastModule
      ),
  },
  {
    path: "dashboard-social-media",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import(
        "app/modules/dashboard-social-media/dashboard-social-media.module"
      ).then((m) => m.DashboardSocialMediaModule),
  },
  {
    path: "dashboard-shop",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-shop/dashboard-shop.module").then(
        (m) => m.DashboardShopModule
      ),
  },
];
